@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap');

body {
    font-family: 'Inter', sans-serif !important;
    background: #fafafa !important;
}

.navigation {
    display: flex;
    background: #fff;
    &__inner {
        width: 1224px;
        margin: 0 auto;
        padding: 10px;
        display: flex;
        align-items: center;
        img {
            height: 30px;
        }
        span.logo_sparator {
            width: 2px;
            height: 22px;
            display: block;
            background: #1B69B8;
            margin: 0px 24px;
        }
        h4.logoText {
            font-style: normal;
            font-weight: 600;
            font-size: 23px;
            line-height: 40px;
            text-align: center;
            color: #0E4A87;
        }
    }
}

.banner {
    background-size: cover;
    &__inner {
        width: 100%;
        max-width: 1224px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        height: 357px;
        &.center-text {
            justify-content: center;
            text-align: center;
        }
        h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            color: #FFFFFF;
            line-height: 60px;
        }
    }
}

.content_section {
    position: relative;
    min-height: 600px;
    &__inner {
        width: 100%;
        max-width: 1224px;
        margin: 0 auto;
        padding: 60px 0px;
        .title {
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 34px;
            text-align: left;
            color: #000000;
        }
        .content_grid {
            display: flex;
            margin: 0px -15px;
        }
    }
}

.card_box {
    width: 25%;
    &__inner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 10px;
        background: #fff;
        height: 150px;
        border-radius: 10px;
        border: 1px solid #98C8F9;
        transition: all 0.3s;
        transform: scale(1);
        &:hover {
            box-shadow: 0px 40px 50px #98c8f942;
            transition: all 0.3s;
            transform: scale(1.02);
        }
        a.clickarea {
            position: absolute;
            width: 100%;
            height: 100%;
        }
        img {
            height: 65px;
            margin-bottom: 20px;
        }
        h4 {
            font-style: normal;
            font-weight: 400;
            font-size: 23px;
            line-height: 28px;
            text-align: center;
            color: #000000;
        }
    }
}


.footer {
    background: #F3F3F4;
    &__inner {
        width: 100%;
        max-width: 1224px;
        margin: 0 auto;
        display: flex;
        padding: 40px 0px;
        span {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            color: #7E7F80;
            margin-right: 15px;
        }
        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            color: #7E7F80;
            margin-right: 15px;
        }
    }
}


.accordion {
    position: relative;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #C5E1FD;
    height: 53px;
    transition: all 0.3s;
    margin-bottom: 10px;
    &.active {
        height: 100% !important;
        transition: all 0.3s;
    }
    &_header {
        padding: 15px;
        position: relative;
        cursor: pointer;
        &_title {
            font-style: bold;
            font-weight: 700;
            line-height: 23px;
            color: #000000;
            margin-right: 50px;
        }
        .caret {
            position: absolute;
            right: 25px;
            top: 20px;
            transform: rotate(0deg);
            transition: all 0.3s;
            &.active {
                transform: rotate(90deg);
                transition: all 0.3s;
            }
        }
    }
    &_content {
        padding: 15px;
        background: #fafafa;
    }

    //STYLE KONTEN
    p {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 10px;
    };
    ol, ul{
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 10px;
        margin-left: 50px;
        li{
            list-style-type: circle;
        };
    };
    img,.img{
        margin: 10px;
        width: 200px;
    };
    b,.b { 
        font-style: bold;
        font-weight: 700;
     };
    i { font-style: italic };
    h1,h2,h3,h4,h5,h6{
        margin-top:0px;
        margin-bottom:.5rem;
        font-style: bold;
    };
    h1{ font-size:2.5rem }
    h2,.h2{ font-size:2rem }
    h3,.h3{ font-size:1.75rem }
    h4,.h4{ font-size:1.5rem }
    h5,.h5{ font-size:1.25rem }
    h6,.h6{ font-size:1rem }
    strong,.strong{ font-weight: bold; }
    em,.em{ font-style: italic; }
    i,.i{ font-style: italic; }
    blockquote,.blockquote {
        display: block;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 40px;
        margin-right: 40px;
      }
}

.lang {
    flex: 1 1;
    display: flex;
    justify-content: end;
    position: relative;
}
.flagContainer {
    cursor: pointer;

}
.flagContainer img {
    width: 32px;
    height: auto;
}

.boxPopup {
    background: #F3F3F4;
    padding: 0px 20px;
    position: absolute;
    top: 37px;
    &__Item {
        display: flex;
        align-items: center;
        margin: 20px 0px;
        cursor: pointer;
        p {
            margin-left: 10px;
            font-size: 14px;
        }
        img {
            width: auto;
            height: 25px;
        }
    }
}

a.backBtn {
    margin-right: 10px;
    transform: scale(0.9);
}

.loading img {
    width: 40px;
}
.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 60px 0px;
    margin: 20px 0px 0px;
}

.likeBtn {
    text-align: center;
    margin-top: 80px;
    h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 19px;
        line-height: 23px;
        color: #000000;
        margin-bottom: 30px;
    }
    .response {
        display: flex;
        flex-direction: row;
        justify-content: center;
        h4 {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
            margin-bottom: 10px;
        }
        .responseItem {
            margin: 0px 30px;
            margin-bottom: 40px;
            cursor: pointer;
            padding: 20px;
            border: solid 1px transparent;
            background-color: transparent;
            transition: all 0.3s;
            &.active {
                transition: all 0.3s;
                background: #eaf2ff;
                border-radius: 10px;
                padding: 20px;
                border: solid 1px #1a3b6c1c;
            }
            &.activeRed {
                transition: all 0.3s;
                background: #f4433614;
                border-radius: 10px;
                padding: 20px;
                border: solid 1px #1a3b6c1c;
            }
        }
    }
}


@media only screen and (max-width: 768px) {
    .banner__inner h2 {
        font-size: 34px;
        line-height: 45px;
        margin-left: 25px;
    }
    .likeBtn {
        text-align: center;
        margin-top: 40px;
    }
    .banner__inner {
        // padding: 40px;
    }
    .content_section {
        padding: 0px 25px;
    }
    .content_section__inner {
        padding: 30px 0px;
    }
    .content_grid {
        // flex-direction: column;
    }
    .card_box {
        width: 50%;
    }
    .content_section__inner .content_grid {
        display: flex;
        margin: 0px -15px;
        flex-wrap: wrap;
    }
    .navigation__inner img {
        width: 30px;
        height: auto;
    }
    h4.logoText {
        font-size: 24px !important;
    }
    .accordion_header {
        min-height: 46px;
    }
    .accordion {
        height: 70px;
        img,.img{
            margin: 10px;
            width: 100%;
        };
    }
    .accordion_header h4 {
        margin-right: 30px;
        font-size: 16px;
    }
}